.app-main-header {
  background-color: #ff5722 !important;
  color: #fff !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  background-color: #41729f !important;
}
.app-sidebar .side-nav {
  width: 226px;
  background: url(assets/images/background.png);
  -webkit-box-shadow: 1px 2px 5px #b4bfc3;
}
.app-logo-content.d-flex.align-items-center.justify-content-center,
.app-sidebar .side-nav {
  background: #41729f !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.app-sidebar .side-nav ul.nav-menu li a:hover,
.cbutton:hover {
  background: #4c7aa5 !important;
  color: #fff;
}
h3.title-head {
  background: #41729f;
  padding: 5px;
}
ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #41729f !important;
  color: #fff;
}
label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #1bc793;
}
.page-heading i.zmdi-hc-fw {
  color: #41729f;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #41729f !important;
  color: white;
}
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #3b5f7f !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #41729f !important;
}

div#form-dialog-title {
  background: #41729f;
}
span.right {
  float: right;
  font-size: 15px;
  color: white;
}
/* Status labels*/
span.label {
  background: #95999c;
  text-transform: capitalize;
}
span.confirmed,
span.created {
  background: #ffcb00e0;
}
span.label.instant {
  background: green;
}

span.label.schedule {
  background: orangered;
}
.page-heading i.zmdi-hc-fw {
  color: #41729f;
  font-size: larger;
}

span.searching,
span.WatingForDriver {
  background: #e0b91ffc;
}

span.started,
span.Scheduled {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}

span.completed,
span.Completed,
span.Online,
span.active,
span.approved,
span.Yes,
span.FindingTrips,
span.yes {
  background: green;
}

span.cancelled,
span.Cancelled,
span.pending,
span.cancel,
span.rejected,
span.inactive,
span.No,
span.no {
  background: #fd2121;
}

span.Offline {
  background: #eb4d4d;
}
table.surgeTime {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.surgeTime {
  background: #d3d3d3;
  padding: 5px 20px;
  border-radius: 5px;
}
table.surgeTime td,
table.surgeTime th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

/* .MuiInputBase-input {
  height: 2.1876em !important;
}  */

/* 
 .MuiInputLabel-formControl {
  top: -10px;
} */
